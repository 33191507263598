:deep(.van-divider) {
  margin: 0.96rem 0;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-36 {
  margin-top: 0.96rem;
}

.text-right {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.perRepl5 {
  background: url("../../assets/shuiyin.png") repeat;
}

.perRepl5 /deep/.van-loading {
  margin-top: 43vh;
}

.perRepl5 .aiTips {
  background: rgba(249,250,252,0.9);
  color: #999;
  font-size: 0.32rem;
  width: 100%;
  padding: 0.266667rem 0;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 10;
}

.perRepl5 .cont {
  background: rgba(249,250,252,0.9);
  padding: 0.426667rem;
}

.perRepl5 .cont .topdiv {
  height: 2.026667rem;
  display: flex;
  margin-bottom: 0.32rem;
  border-radius: 0.426667rem;
  background: rgba(255,255,255,0.5);
}

.perRepl5 .cont .topdiv .tdsub {
  width: 50%;
  flex-direction: column;
}

.perRepl5 .cont .topdiv .tdsub .mb4 {
  margin-bottom: 0.106667rem;
}

.perRepl5 .cont .topdiv .tdsub .txt {
  width: 100%;
  padding: 0 0.08rem;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
  color: #000C21;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.perRepl5 .cont .topdiv .tdsub .tit {
  color: rgba(0,12,33,0.45);
  font-size: 0.32rem;
  font-style: normal;
  font-weight: 400;
}

.perRepl5 .cont .topdiv .pad05 {
  padding: 0 0.133333rem;
}

.perRepl5 .cont .middiv .cards {
  padding-bottom: 0.426667rem;
  margin-bottom: 0.426667rem;
  border-radius: 0.426667rem;
  overflow: hidden;
  background: rgba(255,255,255,0.5);
}

.perRepl5 .cont .middiv .cards .cardtop {
  height: 1.546667rem;
  padding: 0.453333rem 0.426667rem;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, rgba(234,239,255,0.5) 0%, rgba(255,255,255,0.5) 100%);
}

.perRepl5 .cont .middiv .cards .cardtop img {
  width: 0.64rem;
  margin-right: 0.213333rem;
}

.perRepl5 .cont .middiv .cards .cardtop .titl {
  color: rgba(0,12,33,0.65);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
}

.perRepl5 .cont .middiv .cards .cardtop .tet {
  color: #000C21;
  font-size: 0.48rem;
  font-style: normal;
  font-weight: 500;
}

.perRepl5 .cont .middiv .cards .carcen {
  margin: 0 0.426667rem;
  overflow: hidden;
  margin-bottom: 1.013333rem;
  border-radius: 0.426667rem;
  border: 0.026667rem solid rgba(0,12,33,0.08);
}

.perRepl5 .cont .middiv .cards .carcen .carcentop {
  height: 1.28rem;
  display: flex;
  padding: 0 0.426667rem;
  align-items: center;
  background: #FFF;
  background: linear-gradient(90deg, #F8E3C6 0%, #F2BD74 100%);
}

.perRepl5 .cont .middiv .cards .carcen .carcentop img {
  width: 0.426667rem;
}

.perRepl5 .cont .middiv .cards .carcen .carcentop .tite {
  color: #935C19;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
  margin: 0 0.106667rem;
}

.perRepl5 .cont .middiv .cards .carcen .suggest {
  color: #CD9955;
  text-align: justify;
  font-family: PingFang SC;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  padding: 0.32rem 0.426667rem;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 0.426667rem 0.426667rem;
}

.perRepl5 .cont .middiv .cards .carcen .suggest .mask {
  width: 100%;
  height: 100%;
  border-radius: 0 0 0.426667rem 0.426667rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.106667rem);
  background: rgba(255,255,255,0.45);
}

.perRepl5 .cont .middiv .cards .carcen .suggest .mask .unlock {
  height: 0.8rem;
  padding: 0 0.32rem;
  border-radius: 1.013333rem;
  background: linear-gradient(45deg, #F7E1C5 0%, #F2BB73 100%);
}

.perRepl5 .cont .middiv .cards .carcen .suggest .mask .unlock span {
  color: #744E1F;
  text-align: justify;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  margin-left: 0.16rem;
}

.perRepl5 .cont .middiv .cards .issue {
  margin: 0 0.426667rem;
  margin-bottom: 0.8rem;
  border-radius: 0.426667rem;
  background: rgba(249,250,252,0.9);
  position: relative;
}

.perRepl5 .cont .middiv .cards .issue .lab {
  height: 0.746667rem;
  padding: 0 0.32rem;
  position: absolute;
  left: 0;
  top: -0.373333rem;
  text-align: center;
  line-height: 0.746667rem;
  background: linear-gradient(225deg, #033FFF 0%, #48A7FF 100%);
  border-radius: 0.426667rem 0.426667rem 0.426667rem 0.106667rem;
  color: #FFF;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
}

.perRepl5 .cont .middiv .cards .issue .iscot {
  color: rgba(0,12,33,0.85);
  text-align: justify;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.586667rem;
  padding: 0.693333rem 0.426667rem 0.32rem;
}

.perRepl5 .cont .middiv .cards .answer {
  margin: 0 0.426667rem;
  border-radius: 0.426667rem;
  background: rgba(255,250,241,0.5);
  position: relative;
}

.perRepl5 .cont .middiv .cards .answer .lab {
  width: 2.346667rem;
  height: 0.746667rem;
  position: absolute;
  right: 0;
  top: -0.373333rem;
  text-align: center;
  line-height: 0.746667rem;
  background: linear-gradient(225deg, #FFA216 0%, #FFC700 100%);
  border-radius: 0.426667rem 0.426667rem 0.106667rem 0.426667rem;
  color: #FFF;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
}

.perRepl5 .cont .middiv .cards .answer .iscot {
  color: rgba(0,12,33,0.85);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  padding: 0.693333rem 0.426667rem 0.32rem;
}

.perRepl5 .cont .middiv .cards .answer .iscot .word {
  height: 1.76rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  text-align: justify;
}

.perRepl5 .cont .middiv .cards .answer .iscot .heightAuto {
  height: auto;
  display: inherit;
}

.perRepl5 .cont .middiv .cards .answer .iscot .playback {
  margin-top: 0.426667rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.perRepl5 .cont .middiv .cards .answer .iscot .playback .pbkbtn {
  width: 2.666667rem;
  height: 0.8rem;
  cursor: pointer;
  border-radius: 0.96rem;
  border: 0.026667rem solid #FFA714;
}

.perRepl5 .cont .middiv .cards .answer .iscot .playback .pbkbtn span {
  color: #FFA714;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  margin-right: 0.106667rem;
  position: relative;
  top: 0.026667rem;
}

.perRepl5 .cont .middiv .cards .answer .iscot .playback .expand {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.perRepl5 .cont .middiv .cards .answer .iscot .playback .expand span {
  color: #FFA714;
  font-family: PingFang SC;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  margin-right: 0.106667rem;
}

.perRepl5 .cont .middiv .cards .answer .iscot .playback .expand img {
  width: 0.426667rem;
  height: 0.426667rem;
}

.perRepl5 .cont .middiv .cards .answer .iscot .playback .expand .reversal {
  transform: rotate(180deg);
}

.perRepl5 .cont .middiv .cards .answer .iscot .koyou {
  justify-content: end;
}

.perRepl5 .cont .middiv .cards .answer .iscot .koyou .pbkbtn {
  margin-right: 0.426667rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm {
  margin: 0 0.426667rem 0.426667rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm:last-child {
  margin-bottom: 0;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .citop {
  margin-bottom: 0.346667rem;
  display: flex;
  align-items: center;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .citop .category {
  color: #000C21;
  text-align: justify;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .citop .scores {
  flex: 1;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .citop .mw160 {
  max-width: 4.266667rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .citop .grade {
  color: rgba(0,12,33,0.65);
  font-size: 0.373333rem;
  font-weight: 400;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot {
  padding: 0.32rem 0.426667rem;
  border-radius: 0.426667rem 0.106667rem 0.426667rem 0.426667rem;
  background: rgba(249,250,252,0.3);
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .word {
  color: rgba(0,12,33,0.85);
  text-align: justify;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .other {
  padding: 0.426667rem;
  border-radius: 0.213333rem;
  background: rgba(255,255,255,0.5);
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .other .ohitm {
  display: flex;
  align-items: center;
  margin-bottom: 0.426667rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .other .ohitm:last-child {
  margin-bottom: 0;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .other .ohitm img {
  width: 0.666667rem;
  height: 0.666667rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .other .ohitm .spw {
  color: rgba(0,12,33,0.85);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  margin: 0 0.426667rem 0 0.213333rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .other .ohitm .spl {
  flex: 1;
  max-width: 3.2rem;
  margin-right: 0.426667rem;
}

.perRepl5 .cont .middiv .cards .cardbot .carditm .cibot .other .ohitm .spn {
  color: rgba(0,12,33,0.85);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.586667rem;
}

.perRepl5 .cont .botdiv {
  padding: 0.426667rem;
  border-radius: 0.426667rem;
  background: rgba(255,255,255,0.5);
}

.perRepl5 .cont .botdiv p {
  color: #000C21;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.64rem;
  margin-bottom: 0.213333rem;
}

.perRepl5 .cont .botdiv span {
  color: rgba(0,0,0,0.45);
  text-align: justify;
  font-size: 0.32rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.533333rem;
}

.wrapper {
  display: none;
}

.wrapper * {
  box-sizing: border-box;
}

.wrapper .van-loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.7);
  z-index: 1;
}

.wrapper .personal-report {
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.wrapper .personal-report header {
  background: url("~@/assets/images/personal-report-banner.png") top left;
  background-size: 100% 100%;
  padding: 0.64rem;
}

.wrapper .personal-report header h1.title {
  text-align: center;
  font-size: 0.533333rem;
  line-height: 0.533333rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0.506667rem;
}

.wrapper .personal-report header .divider {
  height: 0.026667rem;
  border: 0.026667rem solid #ffffff;
}

.wrapper .personal-report header .basic-info {
  justify-content: space-between;
  margin: 0.453333rem 0 0;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.533333rem;
}

.wrapper .personal-report header .basic-info-item>p {
  margin-bottom: 0.213333rem;
}

.wrapper .personal-report header .select-container {
  margin-bottom: 0.453333rem;
}

.wrapper .personal-report header .select-container :deep(.van-cell) {
  padding: 0.053333rem 0.266667rem;
  background: transparent;
  border-radius: 0.08rem;
  border: 0.026667rem solid #ffffff;
}

.wrapper .personal-report header .select-container :deep(.van-cell) .van-field__control {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.533333rem;
}

.wrapper .personal-report header .select-container :deep(.van-cell) .van-field__right-icon {
  color: #fff;
  line-height: 1;
}

.wrapper .personal-report header .select-container :deep(.van-cell) .van-field__right-icon .van-icon {
  font-size: 0.373333rem;
}

.wrapper .personal-report section {
  min-height: calc(100vh - 4.8rem);
  background-color: #F9FAFC;
  padding: 1.066667rem 0.64rem;
}

.wrapper .personal-report section .column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.wrapper .personal-report section .module-card {
  margin-bottom: 0.96rem;
}

.wrapper .personal-report section .module-card .module-title {
  font-size: 0.533333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.746667rem;
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-title.left-icon {
  position: relative;
  padding-left: 0.266667rem;
}

.wrapper .personal-report section .module-card .module-title.left-icon::before {
  content: '';
  position: absolute;
  width: 0.106667rem;
  height: 0.48rem;
  background: #1890ff;
  border-radius: 0.053333rem;
  left: -0;
  top: 0.106667rem;
}

.wrapper .personal-report section .module-card .module-content h2.title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.586667rem;
  margin-bottom: 0.213333rem;
}

.wrapper .personal-report section .module-card .module-content h3.title {
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.8rem;
}

.wrapper .personal-report section .module-card .module-content h4.title {
  font-size: 0.373333rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.213333rem;
}

.wrapper .personal-report section .module-card .module-content h4.title.mb-24 {
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score {
  margin-bottom: 0.96rem;
}

.wrapper .personal-report section .module-card .module-content .total-score-area {
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .desc {
  margin-right: 0.4rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(98,112,152,0.65);
  line-height: 0.533333rem;
  margin-bottom: 0.426667rem;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .card {
  background: #ffffff;
  box-shadow: 0.053333rem 0.053333rem 0.266667rem 0 rgba(169,193,205,0.34);
  border-radius: 0.08rem;
  padding: 0.533333rem;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  justify-content: space-between;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .card.space {
  margin: 0.266667rem auto;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .card .text {
  font-weight: 400;
}

.wrapper .personal-report section .module-card .module-content .total-score .copywriting {
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .rank-container {
  margin-bottom: 0.453333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart-legend {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.453333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart-legend-item {
  justify-content: flex-start;
  width: 35%;
  margin-bottom: 0.32rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.64rem;
  white-space: nowrap;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart-legend-item .dot {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  background: #1890ff;
  border-radius: 0.16rem;
  margin-right: 0.213333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart {
  height: 6.666667rem;
  margin-bottom: 0.96rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .score-list-item {
  font-size: 0.373333rem;
  font-weight: 400;
  line-height: 0.533333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .score-list-item .dimension-desc {
  color: rgba(51,51,51,0.85);
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .score-list-item .comment {
  color: #333333;
  margin-top: 0.453333rem;
}